<div class="order-info breadcrumb row c d-flex align-items-center">
    <div class="breadcrumb-items">
        <span v-if="filialName" class="breadcrumb-item">Цена на ф-ле: {{filialName}}</span>
        <span class="breadcrumb-item">Код: {{orderInfo.partner_code}}</span>
        <span class="breadcrumb-item">Контрагент: {{orderInfo.partner_name}}</span>
        <span class="breadcrumb-item">Контакт: {{orderInfo.partner_contact}}</span>
        <span class="breadcrumb-item">№ заказа: {{orderInfo.order_id}}</span>
        <span class="breadcrumb-item">№ ВО: {{orderInfo.incoming_id}}</span>
    </div>
    <Button @click="showModalHandler" :label="'<span>✎</span> ' + partnerNotChosenWarningBtn"></Button>
    <div v-if="!partnerChosen" class="order-info__partner-not-chosen-warning">
        Внимание, укажите контрагента! Иначе, в оферту для товаров с вводом в эксплуатацию не будут включены затраты на выезд инженера до контрагента.
    </div>
</div>
<OrderInfoModal
    v-model:showModal="showModal"
    :order-info="orderInfo"
    :change-order-info="changeOrderInfo"
/>
